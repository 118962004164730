import { del, generateClient } from "@aws-amplify/api";
import {
  fetchMFAPreference,
  fetchUserAttributes,
  fetchAuthSession,
  AuthSession,
  FetchMFAPreferenceOutput,
} from "@aws-amplify/auth";
import { signOutAndDeleteCookies } from '@blings/shared-auth-component'
import { flow, getRoot, Instance, types, cast } from "mobx-state-tree";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { NavigateFunction } from "react-router";
import { Account, FileUpload } from "../API";
import { getAccount } from "../graphql/queries";
import { PATHS, toPath } from "../PATHS";
import { AccountModel } from "./accountStore";
import { IRootModel } from "./Root";

export const UserModel = types
  .model({
    email: types.maybe(types.string),
    accounts: types.maybe(types.array(AccountModel)),
    mfaConfig: types.maybe(
      types.enumeration("MFAConfig", ["UNSET", "DISABLED", "ENABLED"])
    ),
    mfaEnabled: types.maybe(types.boolean),
    identityId: types.maybe(types.string),
    phoneNumber: types.maybe(types.maybeNull(types.string)),
    sub: types.maybe(types.string),
    loadStatus: types.optional(
      types.enumeration("LoadStatus", ["LOADING", "LOADED"]),
      "LOADING"
    ),
    token: types.maybe(types.maybeNull(types.string)),
    session: types.maybe(types.frozen()),
  })
  .views((self) => ({
    get mfaShouldBeEnabled() {
      if (self.mfaConfig === "ENABLED") {
        return true;
      }
      if (self.mfaConfig === "DISABLED") {
        return false;
      }
      return self.accounts?.some((account) => account.mfaEnabled) || false;
    },
    get isBlingsUser() {
      return self.email?.endsWith("@blings.io") || false;
    },
    get hasBlingsAccess() {
      return (
        self.accounts?.some((account) => account.id === "blings_account") ||
        false
      );
    },
    get mainAccount() {
      return (
        self.accounts?.find((account) => account.id === "blings_account") ||
        self.accounts?.[0]
      );
    },
    accountFromId(id?: string) {
      if (!id) return undefined;
      return self.accounts?.find((account) => account.id === id);
    },
  }))
  .volatile((self) => ({
    loadCallbackFunctions: [] as (() => void)[],
  }))
  .actions((self) => ({
    addFunctionToLoadCallback(func: () => void) {
      if (self.loadStatus !== "LOADED") self.loadCallbackFunctions.push(func);
      else func();
    },
    async signOut(history: NavigateFunction) {
      try {
        await signOutAndDeleteCookies(del);
        getRoot<IRootModel>(
          self
        ).projectsStore.removeProjectsFromLocalStorage();
        getRoot<IRootModel>(self).resetStore();
        history(toPath(PATHS.home));
        window.location.reload();
      } catch (e) {
        console.log(e, "failed to log out");
      }
    },
    load: flow(function* () {
      self.loadStatus = "LOADING";
      const promises = [
        fetchAuthSession(),
        fetchUserAttributes(),
        fetchMFAPreference(),
      ];
      const authResult = yield Promise.all(promises);
      const fullAuth = authResult[0] as AuthSession;
      const attributes = authResult[1] as IUserAttributes;
      const { preferred, enabled } = authResult[2] as FetchMFAPreferenceOutput;
      const session = fullAuth.tokens;
      if (!session) throw new Error("No session");
      if (!session.idToken) throw new Error("No idToken");
      if (!attributes.email) throw new Error("No email");
      const groups = session.idToken.payload["cognito:groups"] as string[];

      self.sub = attributes.sub;
      self.email = attributes.email;
      self.phoneNumber = attributes.phone_number || null;
      self.identityId = fullAuth.identityId as string;
      self.session = session;
      self.mfaConfig =
        !attributes["custom:mfaConfig"] ||
        attributes["custom:mfaConfig"] === "0"
          ? "UNSET"
          : attributes["custom:mfaConfig"] === "1"
          ? "DISABLED"
          : "ENABLED";
      self.mfaEnabled = (enabled?.length || 0) > 0;
      const client = generateClient();
      const accountPromises = [];
      for (const group of groups) {
        const query = insertIntoGraphQlString(
          insertIntoGraphQlString(getAccount, {
            path: ["fonts"],
            key: "variants",
            value: {
              name: true,
              weight: true,
              style: true,
            },
          }),
          {
            path: ["accountType"],
            key: "permissions",
            value: {
              removeBlingLogo: true,
              aiOptimization: true,
            },
          }
        );
        accountPromises.push(
          client.graphql({
            query,
            variables: { id: group },
          })
        );
      }
      const accountsResponse = (yield Promise.all(accountPromises)) as Array<{
        data: { getAccount: Account };
      }>;
      const accounts = accountsResponse.map(({ data: { getAccount } }) => {
        return AccountModel.create({
          id: getAccount.id,
          name: getAccount.name,
          createdAt: getAccount.createdAt,
          updatedAt: getAccount.updatedAt,
          aliasId: getAccount.aliasId,
          minisiteDomain: getAccount.minisiteDomain,
          accountType: getAccount.accountType,
          fonts: getAccount.fonts
            ? getAccount.fonts.map((f) => ({
                fontFamily: f.fontFamily,
                variants: f.variants.map((v) => ({
                  name: v.name,
                  weight: v.weight,
                  style: v.style,
                })),
              }))
            : [],
          fileUploads: (getAccount.fileuploads || []).filter(
            (f) => f
          ) as Array<FileUpload>,
          createNewVideosWithBlingLogo: getAccount.createNewVideosWithBlingLogo,
          mfaEnabled: getAccount.mfaEnabled,
        });
      });
      self.accounts = cast(accounts);
      self.loadStatus = "LOADED";
      self.loadCallbackFunctions.forEach((func) => func());
    }),
  }));
interface IUserAttributes {
  email: string;
  sub: string;
  phone_number: string | null;
  identityId: string;
  "custom:mfaConfig": string | null;
}
type RemoveTypename<T> = T extends Array<infer U>
  ? Array<RemoveTypename<U>>
  : T extends object
  ? {
      [K in keyof T as Exclude<K, "__typename">]: RemoveTypename<T[K]>;
    }
  : T;
const removeTypename = <T>(data: T): RemoveTypename<T> => {
  if (Array.isArray(data)) {
    return data.map((item) => removeTypename(item)) as RemoveTypename<T>;
  }

  if (data !== null && typeof data === "object") {
    const newObj: any = {};

    Object.entries(data).forEach(([key, value]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(value);
      }
    });

    return newObj;
  }

  return data as RemoveTypename<T>;
};
export type IUserModel = Instance<typeof UserModel>;
